import { getDocs, where, query, Timestamp } from 'firebase/firestore'
import type { AdministrationAgreement, MedicationAgreement } from '~/models'

export default function useMedicationAgreements() {
  const { collection, getDocument } = useFirebase()

  async function getMedicationAgreementById(medicationAgreementId: string) {
    return await getDocument<MedicationAgreement>(
      `/MedicationAgreement/${medicationAgreementId}`
    )
  }

  async function getMedicationAgreements(userId: string) {
    const queryMedicationAgreement = query(
      collection('MedicationAgreement'),
      where('UserId', '==', userId)
    )

    const { docs } = await getDocs(queryMedicationAgreement)

    return docs
  }

  async function getMedicationAgreement(
    userId: string,
    medicineArticleId: string
  ) {
    const queryMedicationAgreement = query(
      collection('MedicationAgreement'),
      where('UserId', '==', userId),
      where('AdminAgreementSummary.MedicineArticleId', '==', medicineArticleId)
    )

    const { docs } = await getDocs(queryMedicationAgreement)

    return docs
  }

  async function getAdministrationAgreements(
    id: string
  ): Promise<AdministrationAgreement | undefined> {
    const queryAdministrationAgreement = query(
      collection('MedicationAgreement', id, 'AdministrationAgreement')
    )

    const { docs } = await getDocs(queryAdministrationAgreement)

    if (docs.length === 0) {
      return undefined
    }

    let lastIterationDate: Timestamp | null = null
    let administrationAgreement

    // Get the latest administration agreement by looping over documents
    // and check if the iteration date is oldest

    for (const doc of docs) {
      const agreement = doc.data() as AdministrationAgreement
      const currentIterationDate = agreement.ProactiveNextIterationDate

      if (currentIterationDate === null) {
        continue
      }

      if (
        lastIterationDate === null ||
        lastIterationDate?.seconds < currentIterationDate?.seconds
      ) {
        lastIterationDate = currentIterationDate
        administrationAgreement = doc.data()
      }
    }

    return administrationAgreement as AdministrationAgreement
  }

  return {
    getAdministrationAgreements,
    getMedicationAgreement,
    getMedicationAgreementById,
    getMedicationAgreements
  }
}
